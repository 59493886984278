<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="机构名称" span="8">
          <vxe-input v-model="searchData.orgName" placeholder="请输入机构名称" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:orgName="{ row }">
          <p class="link-a" @click="goPagess('detail', row)">
            {{ row.orgName }}
          </p>
        </template>
        <template v-slot:leaveTime="{ row }">
          <span>{{ $tools.reversalTime(row.leaveTime) }}</span>
        </template>
        <template v-slot:factTime="{ row }">
          <span>{{ $tools.reversalTime(row.factTime) }}</span>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
        </template>
      </vxe-grid>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        ></vxe-pager>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center',
        },
        {
          field: 'orgName',
          title: '机构名称',
          showOverflow: true,
          align: 'center',
          minWidth: '160',
          slots: {
            default: 'orgName',
          },
        },
        {
          field: 'address',
          title: '机构地址',
          showOverflow: true,
          align: 'center',
          minWidth: '200',
        },
        {
          field: 'bedNumber',
          title: '床位数',
          showOverflow: true,
          align: 'center',
          minWidth: '80',
        },
        {
          field: 'emptyBedNumber',
          title: '空床位数',
          showOverflow: true,
          align: 'center',
          minWidth: '80',
        },
        {
          field: 'mainPerson',
          title: '负责人姓名',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'mainPersonCont',
          title: '负责人联系方式',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          title: '操作',
          width: 240,
          showOverflow: true,
          slots: {
            default: 'operation',
          },
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getmechanismCareListServe', 'delorgInfo']),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goStaff(type, itemData) {
      this.$router.push({
        name: 'AddStaff',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id,
        },
        query: {
          item: JSON.stringify(itemData),
        },
      })
    },
    goAged(type, itemData) {
      this.$router.push({
        name: 'AddAged',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id,
        },
        query: {
          item: JSON.stringify(itemData),
        },
      })
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddManage',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
        },
      })
    },
    goPagess(type, itemData) {
      this.$router.push({
        name: 'AgedDetail',
        params: {
          type: type,
          id: itemData.id,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
        },
      })
    },
    getLists() {
      this.loading = true
      this.getmechanismCareListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          orgName: this.searchData.orgName,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delorgInfo({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
